.explains {
  background-color: #fee9ef;
}

.explainsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fee9ef;
  min-height: 100vh;
  padding: 0 15%;
  color: #482317;
}

.explainsText p {
  font-size: 1.35em;
  /* text-align: center; */
}

.projectButton {
  display: flex;          /* Use flexbox to align items */
  justify-content: center; /* Center items horizontally in the container */
  align-items: center;     /* Center items vertically in the container */
  margin-top: 40px;        /* Optional: add some space on the top */
}

.explainsImage {
  width: 50%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

@media (max-width: 991px) {
  .explainsContainer {
    padding: 0 10%; /* Adjust padding for smaller screens */
  }

  .explainsText {
    /* Ensures text takes the full width on smaller screens and is centered */
    width: 100%;
    /* text-align: center; */
  }

  .explainsImage {
    /* Adjust the image width for smaller screens */
    width: 80%;
    margin-top: 20px; /* Adjust the space above the image */
  }

  .projectButton {
    margin-top: 20px; /* Adjust the space above the button */
  }
}