
.heroContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;  /* New property */
  /* background-color: #ffd6e1; */
  background-color: #fee9ef;
  /* background: linear-gradient(90deg, #EF5331, #F7B8B8); */
  height: 100vh; /* adjust as needed */
  padding: 0 20%;
  color: #482317;
}


.heroText {
  flex: 1;
  /* Add additional CSS for the text as needed */
}

.heroText h2 {
  min-height: 2em; /* Change this value according to your needs */
  font-size: 3rem;
}

.heroText p {
  font-size: 1.35em
}

.greeting {
  font-size: 1.7em; /* Adjust to desired size */
  font-style: italic;
}

.typewriterText {
  font-size: 3em; /* Adjust to desired size */
  min-height: 3em; /* Keep in sync with font-size for layout stability */
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #f06c9b, #F7B8B8);
  -webkit-background-clip: text;
  background-clip: text;
}

.buttonContainer {
  margin-top: 40px; /* adjust this value to your needs */
}


.researchButton {
  /* Add your button styles here */
  width: 270px;
  height: 50px;
  margin: 20px 0;
  /*background: linear-gradient(90deg, #EF5331, #F7B8B8); */
  background: linear-gradient(90deg, #f06c9b, #F7B8B8);
  text-align: center;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  color: #482317;
  border: 1.1px solid #482317;
  border-radius: 15px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
}

.researchButton:hover {
  

}
  
@media (max-width: 390px) {
  .buttonContainer {
    display: flex;
    justify-content: center;  /* Horizontally centers the button */
    align-items: center;      /* Vertically centers the button, if needed */
}

  .heroContainer {
    display: flex;
    justify-content: center;  /* Centers children horizontally */
    align-items: center;      /* Centers children vertically */
}

  .buttonContainer {
    display: flex;
    justify-content: start;  /* Aligns the button to the start (left) */
}

  
}