.projects {
    background-color: #fee9ef;
}

.projectsContainer {
    padding: 2rem 15%; /* You can increase the padding as needed */
}

.myProjectsTitle {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, #f06c9b, #F7B8B8);
    -webkit-background-clip: text;
    background-clip: text;
    margin-bottom: 5rem; /* Adds space below the title */
    text-align: center;  /* Centers the title text */
}


.projectTitle {
    color: #482317;
    margin-bottom: 2rem; 
    /* Removed text-align: center; */
}


.projectImage {
    max-width: 100%;
    height: auto;   
    max-height: 300px;
}

.row.projects {
    margin-bottom: 8rem; /* Adds space between the rows */
}

.projectInfo {
    margin-bottom: 2rem; /* Adds space between the project information and the button */
}

.awardedLink:hover {
    color: #482317; /* Replace [YourHoverColorHere] with the desired color */
}

.projectButton {
    /* Add your button styles here */
    width: 270px;
    height: 50px;
    margin: 20px auto;
    /*background: linear-gradient(90deg, #EF5331, #F7B8B8); */
    background: #f884ad;
    text-align: center;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    color: #482317;
    border: 1.1px solid #482317;
    border-radius: 15px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
  }
  
a.projectButton {
    margin-top: 0px
}

  .projectButton:hover {
    background: #F7B8B8;
  
  }

  .icon-large {
    font-size: 2.1rem; /* Adjust the size as needed */
    display: block;    /* Makes the icon a block-level element */
    margin-left: auto; /* Automatically adjust the left margin */
    margin-right: auto; /* Automatically adjust the right margin */
    margin-top: 1.7rem;
    margin-bottom: 0px; /* Adds some space below the icon */
    text-align: center; /* Center-aligns the icon */
}

  

  @media (max-width: 991px) {
    .projectImage {
        max-width: 100%;
        height: auto;
    }
    
    .projectTitle, .projectDescription, .projectButton {
        text-align: center;
    }
  
    .projectTitle {
        margin-top: 7%;
    }
    
    .projectButton {
        display: block;  /* Set the button to block so it takes the full width of its parent */
        margin-left: auto;  /* Automatically adjust the left margin */
        margin-right: auto; /* Automatically adjust the right margin */
    }
    
}


