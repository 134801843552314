.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fee9ef;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%
}

.contact-title {
    /* -webkit-text-fill-color: transparent; */  /* Commented out */
    /* -webkit-background-clip: text; */  /* Commented out */
    /* background-clip: text; */  /* Commented out */
    color: #482317 !important;
    text-align: center;
    margin-bottom: 3%;
}

.contactLinks a{
    font-size: 1.20rem;
    margin-bottom: 2%;
}

.attribution {
    margin-top: 3%;
}
.contactLinks, .attribution {
    display: flex;
    justify-content: center;
    /* Add any other necessary styles for these classes */
}

@media (max-width: 664px) {
    .contactLinks {
        flex-direction: column;
        align-items: center;  /* To center-align each link horizontally */
    }

    .contactLinks a {
        width: 100%;  /* Make each link full width of the parent */
        text-align: center;  /* Center the text/icon in the link */
    }

    .attribution {
        text-align: center;
    }
}