.cv-container {
    /* This ensures any other content in this container will be left-aligned */
    text-align: left;
    padding: 2rem 15%; /* You can increase the padding as needed */
    color: #482317;
}

.title {
    text-align: center;
}

.download-section {
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
}

.download-section a {
    color: #482317; /* Link color */
    text-decoration: underline; /* Making it look more like a regular link */
    transition: color 0.3s ease; /* Transition effect when hovering */
    cursor: pointer; /* To indicate it's clickable */
    font-size: 0.92rem;
}

.download-section a:hover {
    color: #815749; /* Color on hover */
    /* You can remove the background-color property since it's not needed for a regular link */
}

.education-section {
    margin-bottom: 7%;
}

.section-heading {
    font-size: 2.5rem;
    margin-top: 6%;
    margin-bottom: 2.7%;
}

.school-title {
    font-size: 1.4rem;
    margin-bottom: 1.7%;
}

.degree, .position {
    font-style: italic;
}

@media (max-width: 991px) {
    .section-heading {
        font-size: 2rem;
        margin-top: 19%;
        margin-bottom: 2.7%;
    }
    
    .school-title {
        font-size: 1.1rem;
        margin-bottom: 1.7%;
    }
    
  }