.about {
  justify-content: space-between;
  align-items: center;
  background-color: #fee9ef;
  padding: 20px;
  min-height: 100vh;
}

.title {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #f06c9b, #F7B8B8);
  -webkit-background-clip: text;
  background-clip: text;
  text-align: center;
  margin-bottom: 5%; /* Adjusted for spacing */
}

.aboutText p {
  font-size: 1.35em;
}

.link {
  color: #f06c9b;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* Flex container for image and text */
.contentContainer {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Aligns items vertically */
  margin: 0 auto; /* Centers the container */
  max-width: 1200px; /* Maximum width of the content */
  gap: 30px; /* Spacing between image and text */
}

.aboutImage {
  max-width: 40%; /* Adjust the width as needed */
  height: auto; /* Ensures the aspect ratio is maintained */
}

.aboutText {
  max-width: 50%; /* Adjust the width as needed */
  text-align: left; /* Align text to the left */
}

/* Media query for smaller screens */
@media (max-width: 991px) {

  .about {
    padding: 0 10%;
  }
  .contentContainer {
    flex-direction: column; /* Stack image and text vertically on smaller screens */
  }

  .aboutImage,
  .aboutText {
    max-width: 100%; /* Full width for smaller screens */
  }

  .aboutImage {
    max-width: 70%;
  }

  .aboutText {
    text-align: center; /* Center text on smaller screens */
  }
}
