.navbar {
    padding: 20px; /* Increase the padding value as needed */
    background-color: #fee9ef; 
  /* background: linear-gradient(90deg, #EF5331, #F7B8B8); */

  }

 
 
  
  .website-button:hover {
    background-image: linear-gradient(78.19deg, #89BD9C 9.16%, #61a0af 29.27%, #f06c9b 47.56%, #f9b9b7 64.01%, #f5d491 79.37%);
  }
 
  
  .navbar-brand {
    font-weight: bold;
    -webkit-text-fill-color: #482317;;
    transition: -webkit-text-fill-color 0.3s ease;
    transition: text-fill-color 0.3s ease;
  }
  
  .navbar-brand:hover {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, #f06c9b, #F7B8B8);
    -webkit-background-clip: text;
    background-clip: text;
  }
  
  
  
  
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 100;
  }
  

  .nav-link {
    color: #482317; /* Or any color you want */
    font-size: 1.1rem;
  }
  
  .nav-link:hover {
    color: #f06c9b; /* Or any color you want for hover state */
  }
  
  
  .website-button {
    margin-left: auto;
    background: linear-gradient(90deg, #EF5331, #F7B8B8);
    padding: 10px 20px;
    color: #482317;
    border: 1.25px solid #482317;
    border-radius: 15px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
  }
  
  